import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';

const TablePageActionButtonGroup = ({ page, count, rowsPerPage, onPageChange, buttonProps, ...props }) => {
  // const [localeMapping, setLocaleMapping] = useState({});
  const { t } = useTranslation('Portal');

  // useEffect(() => {
  //   getLocaleMap(['pagination_first_button', 'pagination_last_button', 'pagination_next_button', 'pagination_previous_button', 'labels_of', 'labels_page'])
  //     .then((response) => {
  //       setLocaleMapping(response);
  //     });
  // }, []);

  const totalPage = Math.max(Math.ceil(count / parseInt(rowsPerPage, 10)), 1);
  return (
    <MKBox display="flex" justifyContent="center" alignItems="center" width="100%" p={1}>
      <Button
        size="small"
        disabled={page === 0}
        onClick={(e) => onPageChange(e, 0)}
        {...buttonProps}
      >
        {/* {localeMapping.pagination_first_button} */}
        {t('pagination_first_button')}
      </Button>
      <MKBox pl={1}>
        <Button
          size="small"
          disabled={page <= 0}
          onClick={(e) => onPageChange(e, page - 1)}
          {...buttonProps}
        >
          {/* {localeMapping.pagination_previous_button} */}
          {t('pagination_previous_button')}
        </Button>
      </MKBox>
      <MKBox
        display="flex"
        justifyContent="center"
        px={2}
      >
        {/* {`${localeMapping.labels_page} ${page + 1} ${localeMapping.labels_of} ${totalPage}`} */}
        {`${t('labels_page')} ${page + 1} ${t('labels_of')} ${totalPage}`}
      </MKBox>
      <MKBox>
        <Button
          size="small"
          disabled={page >= totalPage - 1}
          onClick={(e) => onPageChange(e, page + 1)}
          {...buttonProps}
        >
          {t('pagination_next_button')}
          {/* {localeMapping.pagination_next_button} */}
        </Button>
      </MKBox>
      <MKBox pl={1}>
        <Button
          size="small"
          disabled={page >= totalPage - 1}
          onClick={(e) => onPageChange(e, Math.max(0, totalPage - 1))}
          {...buttonProps}
        >
          {t('pagination_last_button')}
          {/* {localeMapping.pagination_last_button} */}
        </Button>
      </MKBox>
    </MKBox>
  );
};

TablePageActionButtonGroup.propTypes = {
  page: PropTypes.number,
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onPageChange: PropTypes.func,
  buttonProps: PropTypes.object,
};

TablePageActionButtonGroup.defaultProps = {
  page: 0,
  count: 0,
  rowsPerPage: 10,
  onPageChange: () => {},
  buttonProps: {},
};

export default TablePageActionButtonGroup;
