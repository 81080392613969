import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import MKBox from 'components/MaterialKit/MKBox';
import { CircularProgress, Grid } from '@mui/material';
import InputField from 'components/InputField';
import Button from 'components/Button';
import moment from 'moment';
import { useEffect, useState } from 'react';
import MKTypography from 'components/MaterialKit/MKTypography';
import { getLocaleMap } from 'utils/locales';

const SkeletonReportGeneratorForm = ({ onSubmit, ...props }) => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['start_time', 'end_time', 'generate_skeleton_report'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const [startTime, setStartTime] = useState(moment().startOf('day').toISOString());
  const [endTime, setEndTime] = useState(moment().endOf('day').toISOString());

  const initialValues = {
    start_time: startTime,
    end_time: endTime,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, touched, setFieldValue }) => {
        return (
          <MKBox
            component="form"
            role="form"
            onSubmit={handleSubmit}
            style={{
              padding: '0px',
              fontSize: '16px',
              marginBottom: '30px',
            }}
          >
            <Grid container display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
              <Grid sx={3} md={3} lg={3}>
                <InputField
                  type="datetime"
                  name="start_time"
                  label={localeMapping.start_time}
                  maxDate={endTime}
                  value={values.start_time}
                  onChange={(e) => {
                    setFieldValue('start_time', e[0]);
                    setStartTime(e[0]);
                  }}
                  sx={{
                    marginRight: 10,
                  }}
                />
              </Grid>
              <Grid sx={3} md={3} lg={3}>
                <InputField
                  type="datetime"
                  name="end_time"
                  label={localeMapping.end_time}
                  minDate={startTime}
                  value={values.end_time}
                  onChange={(e) => {
                    setFieldValue('end_time', e[0]);
                    setEndTime(e[0]);
                  }}
                  sx={{
                    marginRight: 10,
                  }}
                />
              </Grid>
              <Grid sx={3} md={3} lg={3}>
                {errors.form && (
                  <MKTypography variant="caption" color="error">
                    {errors.form}
                    &nbsp;
                  </MKTypography>
                )}
                <Button
                  type="submit"
                  variant="gradient"
                  color="info"
                  disabled={isSubmitting}
                  fullWidth
                >
                  {isSubmitting ? (
                    <CircularProgress color="white" />
                  ) : localeMapping.generate_skeleton_report}
                </Button>
              </Grid>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

SkeletonReportGeneratorForm.propTypes = {
  onSubmit: PropTypes.func,
};

SkeletonReportGeneratorForm.defaultProps = {
  onSubmit: () => {},
};

export default SkeletonReportGeneratorForm;
