import MKBox from 'components/MaterialKit/MKBox';
import React, { useCallback, useEffect, useState } from 'react';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import Button from 'components/Button';
import MKTypography from 'components/MaterialKit/MKTypography';
import { Grid, Container, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { datetimeFormatter } from 'utils/datetime';
import { getDevice, rebootDevices, upgradeDevicesSoftwares, updateCamerasIP } from 'api/alpha/devices';
import HexagonIcon from '@mui/icons-material/Hexagon';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import DebugDeviceSection from 'sections/DebugDevice';
import { getLocaleMap } from 'utils/locales';
import moment from 'moment';
import { generateAlarm } from 'api/alpha/alerts';

// Inside your component
const AVDevicesViewSection = () => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['edit', 'devices', 'centers', 'device_id', 'device_number', 'ai_script_version', 'watchdog_script_version', 'api_url', 'runtime_websocket_url', 'config_websocket_url', 'add_to', 'linked_camera', 'online', 'last_modified_time',
      'reboot', 'upgrade_ai_software', 'upgrade_watchdog_software',
    ])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const [searchParams] = useSearchParams();
  const [device, setDevice] = useState(null);
  const deviceId = searchParams.get('device_id');
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (!deviceId) return;
    getDevice(deviceId, { $expand: 'ward_id/center_id/service_provider_id,fall_detection_script,watchdog_script,cameras' })
      .then(({ data }) => {
        setDevice(data);
      });
  }, [deviceId]);

  const onEditClick = () => {
    navigate(`/devices/edit?device_id=${deviceId}`);
  };

  const onPressUpgradeAI = useCallback(() => {
    const body = {
      device_id: [deviceId],
      type: 'download_fall_detection_script',
    };
    return upgradeDevicesSoftwares(body)
      .then((response) => {
        console.log("upgraded device's ai:", deviceId, '\nwith response', response);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [deviceId, setAuth]);

  const onPressUpgradeWatchdog = useCallback(() => {
    const body = {
      device_id: [deviceId],
      type: 'download_watchdog_script',
    };
    return upgradeDevicesSoftwares(body)
      .then((response) => {
        console.log("upgraded device's watchdog:", deviceId, '\nwith response', response);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [deviceId, setAuth]);

  const onPressUpdateIP = useCallback(() => {
    const body = {
      device_id: deviceId,
    };
    return updateCamerasIP(body)
      .then((response) => {
        console.log('rebooted device:', deviceId, '\nwith response', response);
      });
  }, [deviceId]);

  const onRebootClick = useCallback(() => {
    const body = {
      device_id: [deviceId],
    };
    return rebootDevices(body)
      .then((response) => {
        console.log('rebooted device:', deviceId, '\nwith response', response);
      });
  }, [deviceId]);

  const onPressGenerateAlarm = useCallback(() => {
    if (device?.device_physical_id) {
      let formattedUrl;
      if (device?.runtime_websocket_url.startsWith('ws:')) {
        formattedUrl = device?.runtime_websocket_url.replace('ws:', 'http:');
      } else if (device?.runtime_websocket_url.startsWith('wss:')) {
        formattedUrl = device?.runtime_websocket_url.replace('wss:', 'https:');
      }

      formattedUrl += `/generate_alarm?device_id=${device.device_physical_id}&timestamp=${moment().unix()}&camera_id=${device?.cameras[0].camera_id}&Behaviour=%5B%22test%22%5D`;
      // };
      return generateAlarm(formattedUrl)
        .then((response) => {
          console.log('generate alarm:', deviceId, '\nwith response', response);
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
  }, [device, deviceId, setAuth]);

  return (
    <MKBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <AVBreadcrumb
        startingNode={localeMapping.devices}
        items={[localeMapping.centers, device?.device_number || 'Unknown device']}
        showBackButton
        backButtonPath="/devices/list"
      />
      <Container>
        <Grid container spacing={1}>
          <Grid container alignItems="center" sx={{ mb: 1 }}>
            <Grid item xs={12} md={4} lg={3}>
              <MKTypography variant="body1" color="black" fontSize="16px">
                {`${localeMapping.device_id}:`}
              </MKTypography>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <MKTypography variant="body1" color="black" fontSize="16px">
                {device?.device_physical_id}
              </MKTypography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ mb: 1 }}>
            <Grid item xs={12} md={4} lg={3}>
              <MKTypography variant="body1" color="black" fontSize="16px">
                {`${localeMapping.device_number}:`}
              </MKTypography>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <MKTypography variant="body1" color="black" fontSize="16px">
                {device?.device_number}
              </MKTypography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ mb: 1 }}>
            <Grid item xs={12} md={4} lg={3}>
              <MKTypography variant="body1" color="black" fontSize="16px">
                {`${localeMapping.ai_script_version}:`}
              </MKTypography>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <MKTypography variant="body1" color="black" fontSize="16px">
                {device?.fall_detection_script?.name || 'N/A'}
              </MKTypography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ mb: 1 }}>
            <Grid item xs={12} md={4} lg={3}>
              <MKTypography variant="body1" color="black" fontSize="16px">
                {`${localeMapping.watchdog_script_version}:`}
              </MKTypography>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <MKTypography variant="body1" color="black" fontSize="16px">
                {device?.watchdog_script?.name || 'N/A'}
              </MKTypography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ mb: 1 }}>
            <Grid item xs={12} md={4} lg={3}>
              <MKTypography variant="body1" color="black" fontSize="16px">
                {`${localeMapping.api_url}:`}
              </MKTypography>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <MKTypography variant="body1" color="black" fontSize="16px">
                {device?.api_url}
              </MKTypography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ mb: 1 }}>
            <Grid item xs={12} md={4} lg={3}>
              <MKTypography variant="body1" color="black" fontSize="16px">
                {`${localeMapping.runtime_websocket_url}:`}
              </MKTypography>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <MKTypography variant="body1" color="black" fontSize="16px">
                {device?.runtime_websocket_url}
              </MKTypography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ mb: 1 }}>
            <Grid item xs={12} md={4} lg={3}>
              <MKTypography variant="body1" color="black" fontSize="16px">
                {`${localeMapping.config_websocket_url}:`}
              </MKTypography>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <MKTypography variant="body1" color="black" fontSize="16px">
                {device?.config_websocket_url}
              </MKTypography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ mb: 1 }}>
            <Grid item xs={12} md={4} lg={3}>
              <MKTypography variant="body1" color="black" fontSize="16px">
                {`${localeMapping.add_to}:`}
              </MKTypography>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <MKTypography variant="body1" color="black" fontSize="16px">
                {`${device?.ward_id?.center_id?.service_provider_id?.name}/${device?.ward_id?.center_id?.name}/${device?.ward_id?.name}`}
              </MKTypography>
            </Grid>
          </Grid>
          <Grid container alignItems="flex-start" sx={{ mb: 1 }}>
            <Grid item xs={12} md={4} lg={3}>
              <MKTypography variant="body1" color="black" fontSize="16px">
                {`${localeMapping.linked_camera}:`}
              </MKTypography>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <MKTypography variant="body1" color="black" fontSize="16px">
                {device?.cameras.map((camera) => {
                  return (
                    <MKTypography key={camera.camera_id} variant="body1" color="black" fontSize="16px">
                      {`${camera.camera_identifier} (${camera.ip})`}
                    </MKTypography>
                  );
                })}
              </MKTypography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ mb: 1 }}>
            <Grid item xs={12} md={4} lg={3}>
              <MKTypography variant="body1" color="black" fontSize="16px">
                {`${localeMapping.last_modified_time}:`}
              </MKTypography>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <MKTypography variant="body1" color="black" fontSize="16px">
                {datetimeFormatter(device?.lastmoddate)}
              </MKTypography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ mb: 1 }}>
            <Grid item xs={12} md={4} lg={3}>
              <MKBox display="flex" justifyContent="left" alignItems="center" flexDirection="row">
                <MKTypography variant="body1" color="black" fontSize="16px">
                  {`${localeMapping.online}:`}
                </MKTypography>
              </MKBox>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <MKBox display="flex" justifyContent="left" alignItems="center" flexDirection="row">
                { device?.active ? (<HexagonIcon sx={{ color: '#67FF49' }} />) : (<HexagonIcon sx={{ color: '#FF5549' }} />) }
              </MKBox>
            </Grid>
          </Grid>
        </Grid>
        <Stack direction={isMdDown ? 'column' : 'row'} spacing={1}>
          <Button onClick={onEditClick} variant="contained" size="medium" color="warning">{localeMapping.edit}</Button>
          <Button onClick={onRebootClick} variant="contained" size="medium" color="warning">{localeMapping.reboot}</Button>
          <Button onClick={onPressUpgradeAI} variant="contained" size="medium" color="warning">{localeMapping.upgrade_ai_software}</Button>
          <Button onClick={onPressUpgradeWatchdog} variant="contained" size="medium" color="warning">{localeMapping.upgrade_watchdog_software}</Button>
          <Button onClick={onPressGenerateAlarm} variant="contained" size="medium" color="warning">Generate Alarm</Button>
          {/* <Button onClick={onPressUpdateIP} variant="contained" size="medium" color="warning">Update Video Streaming Server IP</Button> */}
        </Stack>

        <DebugDeviceSection devicePhysicalId={device?.device_physical_id} websocketUrl={device?.runtime_websocket_url} />
      </Container>
    </MKBox>
  );
};

export default AVDevicesViewSection;
