/* eslint-disable no-nested-ternary */
import Button from 'components/Button';
import get from 'lodash/get';
import MKBox from 'components/MaterialKit/MKBox';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import MicIcon from '@mui/icons-material/Mic';
import { Instance } from 'wsSocket';
import { withLoading } from 'utils/hoc';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { getLocaleMap } from 'utils/locales';

const TwoWayAudioButton = ({ camera, disconnectCameraInstance, setDisconnectCameraInstance, disconnectTalk, setDisconnectTalk, showLiveStream, handleShowLiveStream, overallCameraInstance, setOverallCameraInstance, setLoading, props }) => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['live_skeleton_video', 'live_video', 'end_call', 'audio_communication'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const [audioSocketConnected, setAudioSocketConnected] = useState(false);
  const [cameraInstance, setCameraInstance] = useState(null);
  const [talkState, setTalkState] = useState(false);
  const location = useLocation();
  const [startTime, setStartTime] = useState(null);
  const [duration, setDuration] = useState('00:00:00');

  const cameraHeaders = useMemo(() => ({
    devID: camera?.id, // 设备VUID
    devPwd: camera?.password, // 设备明文密码
    session: camera?.session_id, // 连接Session，可以是一个固定的随机数，生成后固定下来。
    bTalk: false, // 当前是否对讲
    bListen: false, // 当前是否监听
  }), [camera]);

  const createCameraInstance = useCallback(() => {
    setLoading(true);
    const instance = new Instance();
    instance.InitSocket(cameraHeaders, setCameraInstance, setAudioSocketConnected, setOverallCameraInstance, setLoading);
  }, [cameraHeaders, setLoading, setOverallCameraInstance]);

  // useEffect(() => {
  //   if (overallCameraInstance?.connected) {
  //     setLoading(false);
  //   }
  // }, [overallCameraInstance?.connected, setLoading]);

  // useEffect(() => {
  //   if (!overallCameraInstance) {
  //     createCameraInstance();
  //   }
  // }, [cameraInstance, createCameraInstance, audioSocketConnected, cameraHeaders, overallCameraInstance]);

  const handleConnectTalk = useCallback(() => {
    if (overallCameraInstance && overallCameraInstance?.connected) {
      setLoading(true);
      overallCameraInstance.Talk(cameraHeaders.session, cameraHeaders.devID, true, setLoading);
      setTalkState(true);
      setStartTime(moment().unix());
    }
  }, [cameraHeaders, overallCameraInstance, setLoading]);

  const handleDisconnectTalk = useCallback(() => {
    setLoading(true);
    setTalkState(false);
    setStartTime(null);
    if (overallCameraInstance) {
      overallCameraInstance.Talk(cameraHeaders.session, cameraHeaders.devID, false, setLoading);
    }
  }, [cameraHeaders, overallCameraInstance, setLoading]);

  const handleDisconnectCameraInstance = useCallback(() => {
    handleDisconnectTalk();
    overallCameraInstance.disconnectDev(cameraHeaders.session, cameraHeaders.devID);
    setDisconnectCameraInstance(false);
    setAudioSocketConnected(false);
    setTalkState(false);
    setCameraInstance(null);
    setOverallCameraInstance(null);
  }, [handleDisconnectTalk, overallCameraInstance, cameraHeaders.session, cameraHeaders.devID, setDisconnectCameraInstance, setOverallCameraInstance]);

  // useEffect(() => {
  //   if (disconnectCameraInstance && overallCameraInstance) {
  //     handleDisconnectCameraInstance();
  //   }
  // }, [overallCameraInstance, disconnectCameraInstance, handleDisconnectCameraInstance]);

  // useEffect(() => {
  //   window.addEventListener('unload', () => {
  //     handleDisconnectCameraInstance();
  //   }, true);
  //   return () => {
  //     window.removeEventListener('unload', () => { }, true);
  //   };
  // }, [cameraHeaders, overallCameraInstance, handleDisconnectCameraInstance]);

  // useEffect(() => {
  //   const pathname = get(location, 'pathname');
  //   if (pathname !== '/' && overallCameraInstance) {
  //     handleDisconnectCameraInstance();
  //   }
  // }, [overallCameraInstance, handleDisconnectCameraInstance, location]);

  // useEffect(() => {
  //   let timer;
  //   if (startTime) {
  //     timer = setInterval(() => {
  //       const currentTime = moment().unix();
  //       const timeDifference = currentTime - startTime;
  //       setDuration(moment.utc(timeDifference * 1000).format('HH:mm:ss'));
  //     }, 1000);
  //   }
  //   return () => {
  //     clearInterval(timer);
  //     setDuration('00:00:00');
  //   };
  // }, [startTime]);

  // useEffect(() => {
  //   if (disconnectTalk) {
  //     handleDisconnectTalk();
  //     setDisconnectTalk(false);
  //   }
  // }, [disconnectTalk, handleDisconnectTalk, setDisconnectTalk]);

  // useEffect(() => {
  //   if (showLiveStream && overallCameraInstance?.connected) {
  //     setLoading(true);
  //     overallCameraInstance.ActiveLiveStream(cameraHeaders.session, cameraHeaders.devID, true, 'liveStream', setLoading);
  //   } else if (!showLiveStream && overallCameraInstance) {
  //     setLoading(true);
  //     overallCameraInstance.ActiveLiveStream(cameraHeaders.session, cameraHeaders.devID, false, 'liveStream', setLoading);
  //   }
  // }, [cameraHeaders, setLoading, showLiveStream, audioSocketConnected, overallCameraInstance]);

  return (
    <MKBox>
      <Button
        disabled={!overallCameraInstance?.connected}
        // disabled={!audioSocketConnected}
        onClick={() => {
          if (handleShowLiveStream) {
            handleShowLiveStream();
          } else if (talkState) {
            handleDisconnectTalk();
          } else {
            handleConnectTalk();
          }
        }}
        color={talkState ? 'red' : 'secondary'}
        size="small"
        fontSize="12px"
        startIcon={!handleShowLiveStream && (
          <MicIcon fontSize="inherit" sx={{ color: '#FFF' }} />
        )}
        {...props}
      >
        {handleShowLiveStream ? localeMapping.live_video : talkState ? `${localeMapping.end_call} (${duration})` : localeMapping.audio_communication}
      </Button>
    </MKBox>
  );
};

TwoWayAudioButton.propTypes = {
  camera: PropTypes.object,
  disconnectTalk: PropTypes.bool,
  disconnectCameraInstance: PropTypes.bool,
  setDisconnectCameraInstance: PropTypes.func,
  setDisconnectTalk: PropTypes.func,
  showLiveStream: PropTypes.bool,
  handleShowLiveStream: PropTypes.func,
  overallCameraInstance: PropTypes.object,
  setOverallCameraInstance: PropTypes.func,
  setLoading: PropTypes.func,
  props: PropTypes.object,
};

export default withLoading(TwoWayAudioButton);
