import React, { useCallback } from 'react';
import moment from 'moment';
import { getRadarData } from 'api/alpha/reports';
import Papa from 'papaparse';
import RadarReportGeneratorForm from 'components/RadarReportGeneratorForm';

const RadarReportGenerator = () => {
  const onSubmit = useCallback(async (values) => {
    const params = {
      'timestamp[gte]': moment(values.start_time).valueOf(),
      'timestamp[lte]': moment(values.end_time).valueOf(),
      // '$select': 'timestamp',
      '$top': 4000,
      '$select': 'timestamp,radar_id,breath_bpm,breath_line,heart_bpm,heart_line,distance,signal_intensity,state',
      '$orderby': 'timestamp',
    };
    const allData = [];
    let totalFetchedDataCount = 0;
    let fetchedDataCount;
    let remainDataCount;
    do {
      // eslint-disable-next-line no-await-in-loop
      const response = await getRadarData(params);
      const resHeader = response.headers;
      // eslint-disable-next-line radix
      const totalDataCount = parseInt(resHeader['content-range'].split('/')[1]);
      const data = response?.data;
      console.log(data);
      allData.push(...data);
      fetchedDataCount = data?.length || 0;
      totalFetchedDataCount += fetchedDataCount;
      remainDataCount = totalDataCount - totalFetchedDataCount;
      params.$skip = totalFetchedDataCount;
    } while (fetchedDataCount > 0 && remainDataCount > 0);
    console.log('all data fetched and processed');

    const formattedData = allData.map((datum) => {
      const result = {};
      result.timestamp = datum.timestamp;
      result.radar_id = datum.radar_id;
      result.breath_bpm = datum.breath_bpm;
      result.breath_line = datum.breath_line;
      result.heart_bpm = datum.heart_bpm;
      result.heart_line = datum.heart_line;
      result.distance = datum.distance;
      result.signal_intensity = datum.signal_intensity;
      result.state = datum.state;

      return result;
    });
    const csv = Papa.unparse(formattedData);

    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    let csvURL = null;
    const start = moment(values.start_time).format('YYYY-MM-DD-h:mm-A');
    const end = moment(values.end_time).format('YYYY-MM-DD-h:mm-A');
    const filename = `radar_${start}_to_${end}.csv`;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, filename);
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', filename);
    tempLink.click();
  }, []);

  return (
    <RadarReportGeneratorForm onSubmit={onSubmit} />
  );
};

RadarReportGenerator.propTypes = {
};

export default RadarReportGenerator;
