import { useCallback, useEffect, useMemo, useState } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import { getReports } from 'api/alpha/reports';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import DataTable from 'components/DataTable';
import { datetimeFormatter } from 'utils/datetime';
import Button from 'components/Button';
import MKTypography from 'components/MaterialKit/MKTypography';
import { getLocaleMap } from 'utils/locales';

const ReportsListViewSection = () => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['download'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const { setAuth } = useAuth();
  const [sortBy, setSortBy] = useState('createddate');
  const [sortDirection, setSortDirection] = useState('desc');

  const reportTableColumns = useMemo(() => [
    { field: 'report_id', label: 'ID', sortable: true },
    { field: 'name', label: 'Name', sortable: true },
    { field: 'description', label: 'Description', sortable: true },
    // { field: 'month', label: 'Month of Year', sortable: true },
    // { field: 'ward', label: 'Ward', sortable: true },
    // { field: 'center', label: 'Center', sortable: true },
    { field: 'createddate', label: 'Generated Date', sortable: true },
    { field: 'endpoint', width: '10px' },
  ], []);

  const [reports, setReports] = useState([]);

  const fetchReportsFromApi = useCallback(() => {
    const requestParams = {
      ...(sortBy ? {
        $orderBy: `${sortBy} ${sortDirection}`,
      } : {}) };
    return getReports(requestParams)
      .then(({ data }) => {
        setReports(data);
      })
      .catch((err) => {
        setReports([]);
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth, sortBy, sortDirection]);

  useEffect(() => {
    fetchReportsFromApi();
  }, [fetchReportsFromApi]);

  const onClickSort = useCallback((colName) => {
    const pageTableColumn = reportTableColumns.find((col) => col.field === colName);
    if (pageTableColumn?.sortable) {
      setSortBy(colName);
      setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
    }
  }, [reportTableColumns]);

  const rowData = useMemo(() => {
    if (!reports) return [];
    return reports.map((report) => {
      return {
        ...report,
        createddate: datetimeFormatter(report?.createddate, 'YYYY-MM-DD HH:mm:ss'),
        endpoint: (
          <a href={report.endpoint} download={`${report.month}_${report.center}_${report.ward}_${datetimeFormatter(report.createddate)}` || 'report.pdf'}>
            <Button variant="contained" size="small" color="#29ABE2" onClick={() => { console.log(report.report_id); }}>
              <MKTypography variant="body1" color="white" fontWeight="bold" fontSize="12px">
                {localeMapping.download}
              </MKTypography>
            </Button>
          </a>
        ),
      };
    }).filter(Boolean);
  }, [localeMapping.download, reports]);

  return (
    <MKBox my={2}>
      <DataTable
        data={rowData}
        columns={reportTableColumns}
        idField="report_id"
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSort={onClickSort}
      />
    </MKBox>
  );
};

export default ReportsListViewSection;
