import Dialog from '@mui/material/Dialog';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Image from 'components/Image';
import TwoWayAudioButton from 'sections/TwoWayAudioButton';

const LivestreamDialog = ({ ward, camera, open, onClose, overallCameraInstance, setOverallCameraInstance, imgOutput, setDisconnectCameraInstance }) => {
  // const [liveStreamOutput, setLiveStreamOutput] = useState(null);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} maxWidth="lg">
      <MKBox px={2} py={2} sx={{ backgroundColor: 'white' }}>
        <MKBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <MKTypography variant="body1" fontSize="20px" fontWeight="bold" color="black">
            {`${ward?.center_name}/${ward?.ward_name}/${camera?.camera_identifier}`}
          </MKTypography>
          <IconButton onClick={handleClose} sx={{ color: '#C5010A' }}>
            <CloseIcon />
          </IconButton>
        </MKBox>
        <MKBox display="flex" bgColor="black" minWidth="75vh" minHeight="50vh" justifyContent="center" alignItems="center" sx={{ bgColor: 'black' }}>
          <Grid item padding={0} maxWidth={imgOutput && 300} width={imgOutput ? 300 : '100%'} height={imgOutput ? 300 : '100%'} style={{ backgroundColor: 'black' }}>
            <Image name={!imgOutput && 'liveStream'} src={imgOutput} width="100%" height="100%" />
          </Grid>
        </MKBox>
        {/* <MKBox mt="1rem">
          <TwoWayAudioButton
            camera={camera}
            overallCameraInstance={overallCameraInstance}
            setOverallCameraInstance={setOverallCameraInstance}
            setDisconnectCameraInstance={setDisconnectCameraInstance}
          />
        </MKBox> */}
      </MKBox>
    </Dialog>
  );
};

LivestreamDialog.propTypes = {
  ward: PropTypes.object,
  camera: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  imgOutput: PropTypes.string,
  overallCameraInstance: PropTypes.object,
  setOverallCameraInstance: PropTypes.func,
  setDisconnectCameraInstance: PropTypes.func,
};

export default LivestreamDialog;
