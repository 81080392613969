/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { getLocaleMap } from 'utils/locales';
import WarningIcon from '@mui/icons-material/Warning';
import moment from 'moment';
import LogDisplayerDialog from './logDisplayerDialog';
import { generateContrastingColor } from './helperFunctions';
import { offlineIcon, onlineIcon, presenceIcon } from './ProblemWardCard';

const WardCard = ({ ward, radarData }) => {
  const bgColor = '#3fafaa';
  // const bgColor = generateContrastingColor(ward.center_id);
  const navigate = useNavigate();
  const [logsOpen, setLogsOpen] = useState(false);
  const [localeMapping, setLocaleMapping] = useState({});
  const thirtyWardCenterId = '4d3d02d2-c80f-475c-aa6b-a652b66420db';

  useEffect(() => {
    getLocaleMap(['details', 'show_logs', 'guests', 'alerts', 'emergency_alarm', 'falls', 'heart_rate', 'presence', 'breath_rate', 'radar_status', 'not_registered'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const handleOpenLogModal = () => {
    setLogsOpen(true);
  };

  const handleCloseLogModal = () => {
    setLogsOpen(false);
  };

  return (
    <MKBox sx={{ border: '1.5px solid', backgroundColor: 'gray', borderRadius: '8px' }}>
      <MKBox px={2} py={1} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ borderRadius: '8px 8px 0px 0px', backgroundColor: bgColor }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={7}>
            <MKBox color="#fff" display="flex" flexDirection="column" alignItems="left">
              <MKTypography color="inherit" fontWeight="bold">{ward.ward_name}</MKTypography>
              <MKTypography color="inherit" fontWeight="bold">{ward.center_name}</MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={5}>
            <Button
              variant="contained"
              size="small"
              color="#3583C5"
              fontSize="12px"
              fullWidth
              onClick={() => { navigate(`/users/ward-details?ward_id=${ward.ward_id}`); }}
              endIcon={(
                <ChevronRightIcon fontSize="inherit" />
            )}
            >
              <MKTypography variant="body1" color="white" fontWeight="bold" fontSize="inherit">{localeMapping.details}</MKTypography>
            </Button>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox px={2} py={1} display="flex" flexDirection="column" alignItems="left" sx={{ borderRadius: '0px 0px 8px 8px', backgroundColor: '#EEFDFF' }}>
        <MKTypography variant="body1" fontSize="16px">{`${localeMapping.guests}: ${ward?.guests || 0}`}</MKTypography>
        <MKTypography variant="body1" fontSize="16px">{`${thirtyWardCenterId === ward.center_id ? localeMapping.falls : localeMapping.alerts}: ${ward?.alerts || 0}`}</MKTypography>
        {ward?.patients.length > 0 && (
          <>
            <MKBox display="flex" flexDirection="row" alignItems="center">
              <MKTypography variant="body1" fontSize="16px" sx={{ mr: 1 }}>{localeMapping.radar_status}</MKTypography>
              {ward?.patients[0]?.radar_id
                ? (radarData[ward?.patients[0]?.radar_id] ? (moment().valueOf() - radarData[ward?.patients[0]?.radar_id]?.timestamp > 15000 ? offlineIcon() : onlineIcon()) : offlineIcon())
                : <MKTypography variant="body1" fontSize="16px">{`(${localeMapping.not_registered})`}</MKTypography>}
            </MKBox>
            <MKBox display="flex" flexDirection="row" alignItems="center">
              <MKTypography variant="body1" fontSize="16px" sx={{ mr: 1 }}>{`${localeMapping.heart_rate}:`}</MKTypography>
              <MKTypography variant="body1" fontSize="16px" sx={{ mr: 1 }}>{radarData[ward?.patients[0]?.radar_id]?.heart_bpm || '-'}</MKTypography>
              {radarData[ward?.patients[0]?.radar_id]?.heart_bpm === 0 && <WarningIcon style={{ color: 'yellow' }} />}
            </MKBox>
            <MKBox display="flex" flexDirection="row" alignItems="center">
              <MKTypography variant="body1" fontSize="16px" sx={{ mr: 1 }}>{`${localeMapping.breath_rate}:`}</MKTypography>
              <MKTypography variant="body1" fontSize="16px" sx={{ mr: 1 }}>{radarData[ward?.patients[0]?.radar_id]?.breath_bpm || '-'}</MKTypography>
              {radarData[ward?.patients[0]?.radar_id]?.breath_bpm === 0 && <WarningIcon style={{ color: 'yellow' }} />}
            </MKBox>
            <MKBox display="flex" flexDirection="row" alignItems="center">
              <MKTypography variant="body1" fontSize="16px" sx={{ mr: 1 }}>{`${localeMapping.presence}:`}</MKTypography>
              <MKTypography variant="body1" fontSize="16px" sx={{ mr: 1 }}>{radarData[ward?.patients[0]?.radar_id]?.heart_bpm > 0 ? 'YES' : 'NO'}</MKTypography>
              {radarData[ward?.patients[0]?.radar_id]?.heart_bpm > 0 ? presenceIcon(true) : presenceIcon(false)}
            </MKBox>
          </>
        )}
        <Button
          variant="contained"
          size="small"
          color="#3583C5"
          fontSize="14px"
          onClick={() => handleOpenLogModal()}
          sx={{ mt: 1 }}
        >
          {localeMapping.show_logs}
        </Button>
      </MKBox>
      {logsOpen && (
        <LogDisplayerDialog
          open={logsOpen}
          ward={ward}
          onClose={handleCloseLogModal}
        />
      )}
    </MKBox>
  );
};

WardCard.propTypes = {
  ward: PropTypes.object.isRequired,
  radarData: PropTypes.object,
};

export default WardCard;
