import api from 'api';

export const getReports = (params) => {
  return api.get('/reports', params, 'bearer');
};

export const getReport = (id, params) => {
  return api.get(`/reports/${id}`, params, 'bearer');
};

export const updateReport = (id, body) => {
  return api.put(`/reports/${id}`, body, 'bearer');
};

export const createReport = (body) => {
  return api.post('/reports', body, 'bearer');
};

export const deleteReport = (id) => {
  return api.delete(`/reports/${id}`, 'bearer');
};

export const getCameraRawData = (params) => {
  return api.get('/camera_raw_data_new', params, 'bearer');
};

export const getCameraHistoryData = (params) => {
  return api.get('/camera_history_data', params, 'bearer');
};

export const getRadarData = (params) => {
  return api.get('/radar_data', params, 'bearer');
};
